import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { IPost } from '~/types'
import styled from 'styled-components'
import YoutubeView from '~/components/media-views/YoutubeView'
import VkView from '~/components/media-views/VkView'
import AudioView from '~/components/media-views/AudioView'
import CoverView from '~/components/media-views/CoverView'
import { contentWidth, height16x9, height4x3, linesRem } from '~/utils/styling'
import Ratio from '~/components/Ratio'
import ReadMore from '~/components/ReadMore'
import { GatsbyImageSharpFluidFragment } from '~/types'
import Tag from '~/components/Tag'
import { useAppContext } from '~/contexts/appContext'
import SharePanel from '~/components/SharePanel'
import Carousel, { Modal, ModalGateway } from 'react-images'
import RutubeView from './media-views/RutubeView'
import bgdark from '~/assets/bg-dark-3@2x.png'

interface IPostPreviewProps {
  post: IPost
}

const TagList = styled.div`
  display: flex;
`

const CoverWrapper = styled.div`
  width: 100%;
  max-width: ${contentWidth}px;
  .img {
    cursor: zoom-in;
  }
  @media (max-width: 400px) {
    .img {
      cursor: default;
    }
  }
`

const CarouselWrapper = styled.div`
  width: 100%;
  background-image: url(${bgdark});
  background-size: 160px 160px;
  overflow: hidden;
  border-bottom: 1px solid white;
`

const View: React.FC<{ data: GatsbyImageSharpFluidFragment }> = ({ data }) => (
  <Img
    fluid={data}
    style={{
      maxWidth: `1120px`,
      maxHeight: `640px`,
      margin: `auto`,
    }}
    //@ts-ignore
    objectFit="cover"
    objectPosition="50% 50%"
  ></Img>
)

const PostPreview: React.FC<IPostPreviewProps> = ({ post }) => {
  const { state } = useAppContext()
  let image = null
  let video = null
  let audio = null

  if (post.type === 'video' && post.youtube) {
    video = <YoutubeView link={post.youtube} />
  }

  if (post.type === 'video' && post.vk) {
    video = <VkView link={post.vk} />
  }

  if (post.type === 'video' && post.rutube) {
    video = <RutubeView link={post.rutube} timeOffset={post.timeOffset}/>
  }

  const [modalIsOpen, setModalIsOpen] = useState(false)

  function toggleModal() {
    if (state.pageWidth <= 400) return
    setModalIsOpen(!modalIsOpen)
  }

  if (post.cover) {
    if (post.images) {
      image = (
        <CoverWrapper>
          <CarouselWrapper>
            <Carousel views={post.images} components={{ Footer: null, View }} />
          </CarouselWrapper>
        </CoverWrapper>
      )
    } else {
      const modalJsx = (
        <ModalGateway>
          {modalIsOpen ? (
            <Modal onClose={toggleModal} allowFullscreen={false}>
              <Carousel views={[post.cover]} components={{ Footer: null }} />
            </Modal>
          ) : null}
        </ModalGateway>
      )
      if (
        (post.coverSize.height * 0.8 * contentWidth) / post.coverSize.width >
        height4x3
      ) {
        image = (
          <CoverWrapper>
            {modalJsx}
            <div
              onClick={toggleModal}
              className="img"
              style={{
                maxWidth: `${
                  (post.coverSize.width * height4x3) / post.coverSize.height
                }px`,
                margin: post.type === 'audio' ? `0 auto 20px` : `auto`,
              }}
            >
              <Img
                fluid={post.cover as GatsbyImageSharpFluidFragment}
                style={{
                  maxHeight: `${height4x3}px`,
                }}
                //@ts-ignore
                imgStyle={{
                  objectFit: 'contain',
                }}
              />
            </div>
          </CoverWrapper>
        )
      } else {
        image = (
          <CoverWrapper onClick={toggleModal}>
            {modalJsx}
            <div className="img">
              <Img
                fluid={post.cover as GatsbyImageSharpFluidFragment}
                style={{
                  maxHeight: `${height4x3}px`,
                }}
                imgStyle={{
                  objectFit: 'cover',
                  // border: `3px solid white`,
                  // maxHeight: `${height4x3}px`,
                }}
              />
            </div>
          </CoverWrapper>
        )
      }
    }
  }

  if (post.type === 'audio' || post.type === 'video') {
    audio = (
      <AudioView
        audio={post.audio}
        audioList={post.audioList}
        audioUrl={post.audioUrl}
      />
    )
  }

  const h2Link = React.createRef<HTMLHeadingElement>()

  useEffect(() => {
    h2Link.current.className =
      state.lastVisit < post.dateValue ? 'new-mark' : ''
  }, [state.lastVisit])

  let html = post.html
  let link = undefined
  if (/<!--\s*more\s*-->/i.test(post.html)) {
    const parts = post.html.split(/<!--\s*more\s*-->/i)
    html = parts[0]
    link = post.urlPath
  }

  return (
    <article className="fixed-width mb-5">
      <header>
        <TagList>
          {post.tags.map((label, index) => (
            <Tag label={label} key={index}></Tag>
          ))}
        </TagList>
        <h2 ref={h2Link}>
          <Link to={post.urlPath}>{post.title}</Link>
        </h2>
        <h6>
          <div
            style={{
              display: `flex`,
              flexDirection: `row`,
              alignItems: `stratch`,
            }}
          >
            <span>{post.date}</span>
            <span style={{ flex: `1` }}></span>
            <SharePanel link={post.urlPath}></SharePanel>
          </div>
        </h6>
      </header>
      <main>
        {image}
        {video}
        {audio}
        <ReadMore link={link} html={html} wordCount={post.wordCount}></ReadMore>
      </main>
    </article>
  )
}

export default PostPreview
