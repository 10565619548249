import React, { useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { WordCount } from '~/types'
import { contentWidth, height16x9, headerFontFamilyString } from '~/utils/styling'
import { animateScroll as scroll } from 'react-scroll'



const Container = styled.div<{ collapsed: boolean, readmore: boolean }>`
  width: 100%;
  height: ${props => props.collapsed ? '250px' : 'auto'};
  padding-bottom: ${props => props.collapsed || !props.readmore ? 0 : '80px'};
  position: relative;
  overflow: hidden;
  /* transition: height .5s; */
`

const More = styled.div`
  display: flex;
  justify-content: center;
  align-items: bottom;
  flex-direction: row;
  position: absolute;
  height: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(241, 241, 241);
  background: linear-gradient(180deg, rgba(241, 241, 241,0) 0%, rgba(241, 241, 241,.9) 50%, rgba(241, 241, 241,1) 100%);
  .btn {
    margin: auto;
    background: rgba(195,150,253,0.82);
    color: rgba(108,55,165,0.88);
    padding: 10px 30px;
    border: none;
    border-radius: 50px;
    font-family: ${headerFontFamilyString};
    font-size: .85rem;
    line-height: 1.2rem;
    text-shadow: 0 1px 0 rgba(255,255,255,.2);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 5px rgba(0,0,0,.15);
    text-transform: uppercase;
  }
`

interface IReadMoreProps {
  html: string
  wordCount?: WordCount
  link?: string
}

const ReadMore: React.FC<IReadMoreProps> = ({ html, link, wordCount: { paragraphs, words } }) => {
  const text = html ? html.split(/<[^>]*>/g).join() : ''
  const [readmore, setReadmore] = useState(text.length > 250)
  const [collapsed, setCollapsed] = useState(readmore)
  const containerRef = React.createRef<HTMLDivElement>()

  function onClickHandler() {
    setCollapsed(!collapsed)
    if (!collapsed) {
      const rect = containerRef.current.getBoundingClientRect()
      scroll.scrollMore(-containerRef.current.scrollHeight + 134, {
        duration: 400,
        delay: 0
      })
    }
  }
  
  if (link) {
    return <Container collapsed={collapsed} readmore={readmore}>
      <article ref={containerRef} dangerouslySetInnerHTML={{ __html: html }}></article>
      {readmore && (<More className="more">
        <button className="btn" onClick={() => navigate(link)}>{`Читать полностью →`}</button>
      </More>)}
    </Container>
  }

  return <Container collapsed={collapsed} readmore={readmore}>
    <article ref={containerRef} dangerouslySetInnerHTML={{ __html: html }}></article>
    {readmore && (<More className="more">
      <button className="btn" onClick={onClickHandler}>{collapsed ? `↓ Читать далее` : '↑ Свернуть'}</button>
    </More>)}
  </Container>
}

export default ReadMore